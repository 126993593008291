/*
 * Use this CSS file ONLY FOR EDGE CASES -> refer to GITLAB ISSUES TO EXPLAIN WHY YOU ARE DOING WHAT YOU ARE DOING
 */

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("../assets/Fonts/Heebo-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}

body {
  margin: 0; 
  font-family: 'Heebo', Helvetica, 'Open Sans', Arial, sans-serif;
}